export default [
  {
    title: "Scout.",
    role: "Data Engineer Intern",
    date: "January 2019 - June 2019",
    description: [
      "Worked directly alongside data and engineering teams to assist them build new and innovative tools that help clients underwrite real estate values and identify \
      and determine service provider expertise.",
      "Responsibilities included: assisting to write code to acquire new data sets from various sources; visualization and exploration of existing data sets to \
      recognize trends; assisting to build new innovative visualization tools and dashboards; and working with Machine Learning solutions \
      to enhance processes and analytics.",
    ],
  },
  {
    title: "Thinkful",
    role: "Data Science Track :: Big Data with Spark, Tensorflow and Keras",
    date: "May 2018 - March 2019",
    description: [
      "Extracted actionable insights from a myriad of data sources, using Spark for big data.",
      "Built and executed a pipeline in batch mode, and advanced to processing live streams of data, posing research questions to form \
      possible answers along the way.",
      "Used Python for programming, relying on libraries like Pandas for data manipulation, Matplotlib and Seaborn for visualization, and \
      Scikit-Learn for machine learning.",
      "Explored a multitude of machine learning algorithms ranging from Bayesian classification to Support Vector Machines for Supervised \
      Learning and clustering, neural networks, and natural language processing for Unsupervised Learning.",
    ],
  },
  {
    title: "Lyle, Inc.",
    role: "iOS Developer Intern",
    date: "March 2017 - August 2017",
    description: [
      "Worked directly with the CTO to bolster the application’s functionality using Objective-C.",
      "Reworked the user interface on the profile and chat page screens to improve user experience.",
    ],
  },
]

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Image from "gatsby-image"
import { mainWhite, darkShadow, pallete } from "../../theme/globalStyle"

const query = graphql`
  {
    bg: file(relativePath: { eq: "nyc-image.jpeg" }) {
      childImageSharp {
        fixed(quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const AboutTxt = ({ className }) => {
  const photo = useStaticQuery(query)
  const gmail =
    "https://mail.google.com/mail/?view=cm&fs=1&to=e.v.sirinian@gmail.com&su=&body="

  return (
    <div className={className}>
      <div className="about-center">
        <div className="about">
          <div className="about-txt">
            <div className="about-header">
              <h6>Hi, my name is</h6>
              <h3 className="name">Eric Sirinian</h3>
              <h3 className="intro-line">I enjoy working with data.</h3>
            </div>
            <div className="bio-short">
              <p>
                I'm a software engineer based in New York, NY. I graduated
                Fordham University in May 2019 with a Bachelor of Science degree
                in Computer Science. I love working with complex data, building
                models, and finding meaningful insights. While I maintain an
                analytical nature at the core, I don't shy away from creativity
                to bolster my work.
              </p>
            </div>
            <div className="technologies">
              <p>Technologies I've worked with recently:</p>
              <div className="technology-list">
                <div className="left">
                  <ul>
                    <li>Django</li>
                    <li>React</li>
                    <li>Swift</li>
                    <li>PostgreSQL</li>
                  </ul>
                </div>
                <div className="right">
                  <ul>
                    <li>OpenCV</li>
                    <li>Spark</li>
                    <li>Artificial Neural Networks</li>
                    <li>Natural Language Processing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="photo">
            <Image
              className="photo-edit"
              fixed={photo.bg.childImageSharp.fixed}
            />
          </div> */}
        </div>

        <div className="contact">
          <button type="button" className="btn-primary">
            <a className="gmail" href={gmail} target="_blank">
              Get In Touch
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default styled(AboutTxt)`
  /* background: orange; */
  /* color: ${pallete[4]}; */
  @import url('https://fonts.googleapis.com/css?family=Londrina+Sketch&display=swap');
  color: white;
  pointer-events: none;
  
  @media screen and (min-width: 500px){
    padding: 1rem;
    .bio-short {
      p {
        font-size: 12px;
      }
    }
    .technologies {
      p {
        font-size: 12px;
      }
      ul {
        li {
          font-size: 10px;
        }
      }
    }
    
  }

  @media screen and (min-width: 700px) {
    margin: 2rem 30% 2rem 2rem;
    .bio-short {
      p {
        font-size: 14px;
      }
    }
    .technologies {
      p {
        font-size: 14px;
      }
      ul {
        li {
          font-size: 12px;
        }
      }
    }
  }

  @media screen and (min-width: 1281px) {
    margin: 2rem 50% 3rem 2rem;
    .bio-short {
      p {
        font-size: 16px;
      }
    }
    .technologies {
      p {
        font-size: 16px;
      }
      ul {
        li {
          font-size: 14px;
        }
      }
    }
  }

  .about-center {
    .about {
      /* display: grid;
      grid-template-columns: 1fr 1fr; */
      pointer-events: none;

      .about-txt {
        pointer-events: none;
        .about-header {
          h6 {
            /* color: ${pallete[0]}; */
            color: white;
            font-size: 1.2rem;
            padding-left: 3px;
          }
          .name {
            /* color: ${pallete[0]}; */
            color: white;
            font-size: 3rem;
            /* font-family: 'Londrina Sketch', cursive; */
            margin-bottom: 0;
            line-height: 1.2;
            letter-spacing: 4px;
          }

          .intro-line {
            color: ${pallete[4]};
            font-size: 2rem;
            line-height: 1;
            padding-left: 3px;
          }
        }
        .bio-short {
          p {
            /* color: ${mainWhite}; */
            line-height: 1.9;
            letter-spacing: normal;
          }
        }
        .technologies {
          .technology-list {
            display: grid;
            grid-template-columns: 1fr 2fr;

            ul {
              li {
                list-style-type: none;
              }

              li:before {
                content: "";
                border-color: transparent ${mainWhite};
                border-style: solid;
                border-width: 0.25em 0 0.25em 0.25em;
                display: block;
                height: 0;
                width: 0;
                left: -1em;
                top: 0.9em;
                position: relative;
              }
            }

            .left {
              padding-left: 2rem;
            }
          }
        }
      }
      .photo {
        display: block;
        margin: auto;

        .photo-edit {
          /* border-radius: 50%; */
          box-shadow: ${darkShadow};
          /* transform: rotateY(50deg); */
        }
      }
    }

    .contact {
      padding-top: 2em;
      .btn-primary {
        background: ${pallete[0]};
        pointer-events: auto;

        .gmail {
          text-decoration: none;
          color: ${pallete[4]};
          padding: 1rem;
        }

        &:hover {
          background: transparent;
          .gmail {
            color: ${pallete[0]}
          }
          
        }
      }
    }
  }
`

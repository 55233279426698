import React from "react"

import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const StyledHero = ({ image, children, className }) => {
  return (
    <BackgroundImage className={className} fluid={image}>
      {children}
    </BackgroundImage>
  )
}

export default styled(StyledHero)`
  max-height: calc(100vh);
  background: linear-gradient(rgba(198, 224, 255, 0.7), rgba(0, 0, 0, 0.7));
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

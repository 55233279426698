import React from "react"

import styled from "styled-components"
const JobDescription = ({ role, date, children, className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="header">
          <div className="role">
            <h3>{role}</h3>
          </div>
          <div className="date">
            <h5>{date}</h5>
          </div>
        </div>
        <div className="body">{children}</div>
      </div>
    </div>
  )
}

export default styled(JobDescription)`
  color: white;
  padding: 0;

  h3 {
    margin: 0;
    font-size: 1.5rem;
  }
`

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import StyledHero from "../components/Home/StyledHero"
import AboutTxt from "../components/Home/AboutTxt"
import Resume from "../components/Resume"
import Projects from "../components/Projects/Projects"
import JobsTabView from "../components/TabView/JobsTabView"
import SkillList from "../components/Skills/SkillList"
import { FaBlackTie } from "react-icons/fa"
import ParticleHero from "../components/Home/ParticleHero"
import ProjectsGroup from "../components/Projects/ProjectsGroup"

export default ({ data }) => (
  <Layout>
    {/* <StyledHero image={data.hero.childImageSharp.fluid}>
      <AboutTxt />
    </StyledHero> */}
    <ParticleHero />
    <JobsTabView />
    <SkillList />
    <Projects />
  </Layout>
)

export const query = graphql`
  {
    hero: file(relativePath: { eq: "nyc2-image.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 200, quality: 90, grayscale: true) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
